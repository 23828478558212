<template>
  <v-container
    class="fill-height justify-center"
    tag="section"
  >
    <v-row
      v-show="error"
      justify="center"
    >
      <v-slide-y-transition appear>
        <base-material-card
          class="px-4 py-2"
          color="blue darken-3"
          max-width="100%"
          width="320"
          light
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold">
                {{ error }}
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-btn
              color="blue"
              large
              text
              block
              @click="logout"
            >
              Logout
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImplicitCallback',
  data: () => ({
    error: null
  }),
  async beforeMount () {
    try {
      await this.$auth.handleLoginRedirect()
    } catch (error) {
      if (this.$auth.isInteractionRequiredError(error)) {
        const { onAuthResume, onAuthRequired } = this.$auth.options
        const callbackFn = onAuthResume || onAuthRequired
        if (callbackFn) {
          callbackFn(this.$auth)
          return
        }
      }
      this.error = error.toString()
    }
  },
  methods: {
    async logout () {
      await this.$auth.signOut()
    }
  }
}
</script>
